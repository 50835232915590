import { axios } from "@/utils/request";

/**
* 模块名:
* 代码描述: 推荐视频分页查询
* 作者:陈莉莉
* 创建时间:2022-08-24 17:33:36
*/
export function findRecommendVideoPageList(parameter) {
  return axios({
    url: '/recommendVideo/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam
  })
}

/**
* 模块名:
* 代码描述: 设为推荐视频
* 作者:陈莉莉
* 创建时间:2022-08-24 17:34:36
*/
export function addRecommendVideo(parameter){
  return axios({
    url: "/recommendVideo/add",
    method: "POST",
    data: parameter
  })
}

/**
* 模块名:
* 代码描述: 推荐视频编辑
* 作者:陈莉莉
* 创建时间:2022-08-24 17:35:21
*/
export function editRecommendVideo(parameter){
  return axios({
    url: "/recommendVideo/edit",
    method: "POST",
    data: parameter
  })
}

/**
* 模块名:
* 代码描述: 删除推荐视频
* 作者:陈莉莉
* 创建时间:2022-08-24 17:36:02
*/
export function deleteRecommendVideo(parameter){
  return axios({
    url: "/recommendVideo/delete",
    method: "POST",
    data: parameter
  })
}