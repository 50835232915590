//视频分类接口
import { axios } from '@/utils/request'
/*
 * @Description:
 * @Version:
 * @Author: 彭勃
 * @Date: 2022-02-8 11:15:50
 */

//视频分类分页查询
export function videoClassPageList(parameter) {
  return axios({
    url: '/videoCategory/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}
//视频分类新增
export function videoClassAdd(parameter) {
  return axios({
    url: '/videoCategory/add',
    method: 'post',
    data: parameter,
  })
}
//视频分类编辑
export function videoClassEdit(parameter) {
  return axios({
    url: '/videoCategory/edit',
    method: 'post',
    data: parameter,
  })
}
//视频分类详情
export function videoClassDetail(parameter) {
  return axios({
    url: '/videoCategory/detail',
    method: 'post',
    params: parameter,
  })
}
//视频分类修改状态（启用 禁用 删除）
export function videoClassDelete(parameter) {
  return axios({
    url: '/videoCategory/delete',
    method: 'post',
    data: parameter,
  })
}
//视频分类list集合
export function videoClassList(parameter) {
  return axios({
    url: '/videoCategory/list',
    method: 'post',
    data: parameter,
  })
}
//添加视频推荐位
export function addRecommendPosition(parameter) {
  return axios({
    url: '/recommendPosition/add',
    method: 'post',
    data: parameter,
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

//编辑视频推荐位
export function editRecommendPosition(parameter) {
  return axios({
    url: '/recommendPosition/edit',
    method: 'post',
    data: parameter,
  })
}
//删除视频推荐位
export function removeRecommendPosition(parameter) {
  return axios({
    url: '/recommendPosition/delete',
    method: 'post',
    data: parameter,
  })
}

//视频推荐位详情
export function recommendPositionDetail(parameter) {
  return axios({
    url: '/recommendPosition/detail',
    method: 'post',
    data: parameter,
  })
}

//分页视频推荐位列表
export function recommendPositionPage(parameter) {
  return axios({
    url: '/recommendPosition/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}
//视频推荐位列表
export function recommendPosition() {
  return axios({
    url: '/recommendPosition/list',
    method: 'post',
    data: {},
  })
}
